.add-card-dialog__label {
	font-size: 16px;
	font-family: var(--inter-font);
	color: var(--black-color);
	margin: 35px 0 20px 0;
}

.add-card-dialog__label-first {
	margin-top: 60px;
}

.add-card-dialog__input-long {
	width: 440px;
}

.add-card-dialog__double-field {
	display: flex;
	align-items: center;
}

.add-card-dialog__double-field > div {
	margin-right: 35px;
}

.add-card-dialog__input-short {
	width: 83px;
}

.add-card-dialog__button {
	margin-top: 35px;
}
