.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
}

.title-noData {
	margin-top: 2rem !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	color: var(--primary-grey);
}

.description-noData {
	font-size: 14px !important;
	color: var(--primary-grey);
	text-align: center;
}

.cardContainer {
	display: flex;
	background-color: white;
	width: 100%;
	margin-bottom: 20px;
	box-shadow: 0px 3px 5px rgba(70, 101, 137, 0.35);
	padding: 14px;
	border-radius: 10px;
	align-items: center;
	justify-content: space-between;
}

@media screen and (max-width: 1000px) {
	.container {
		margin-top: 38%;
	}
}
