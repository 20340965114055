/* .tableRow {
	background-color: white;
	border-radius: 10px;
	margin-bottom: 10px;
}

.tableRowStyle {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	border-radius: 10px;
	margin-bottom: 10px;
	box-shadow: 0px 3px 5px rgba(70, 101, 137, 0.35);
}

.tableColumn {
	font-size: 14px !important;
	color: var(--grey-fonts);
}

.tableUserContainer {
	display: flex;
	background-color: white;
	width: 100%;
	margin-bottom: 20px;
	box-shadow: 0px 3px 5px rgba(70, 101, 137, 0.35);
	padding: 14px;
	border-radius: 10px;
	align-items: center;
	justify-content: space-between;
}

.headerContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.containerTable {
	width: auto;
}

.statusColumn {
	font-size: 14px !important;
	color: var(--grey-fonts);
	width: 20%;
	text-align: center;
}

.actionColumn {
	width: 10%;
	text-align: center;
	color: var(--secondary-grey);
}

.customDataGrid .MuiDataGrid-root {
	border-width: 0;
	border-style: none;
} */

.rowStyle {
	color: var(--secondary-grey);
}

/* @media screen and (max-width: 1000px) {
	.containerTable {
		padding-left: 6px !important;
	}

	.statusColumn {
		width: 10%;
	}

	.actionColumn {
		width: 12%;
	}
} */
