.estimate-manage__content {
  margin-top: 55px;
  background-color: white;
}

.estimate-manage__content_mobile {
  margin-top: 55px;
}

.estimate-manage__create {
  border: 1px solid #d9d9d9;
  box-shadow: 0px 5px 20px rgba(70, 101, 137, 0.35);
  border-radius: 5px;
  padding: 50px 0;
  box-sizing: border-box;
}

.estimate-manage__preview {
  width: 325px;
  background-color: var(--secondary2-color);
  border-radius: 5px;
  padding: 50px 20px;
  box-sizing: border-box;
}

.estimate-manage__company-block {
  padding: 0 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  min-height: 240px;
}

.line-gap {
  margin-top: 5px;
}

.line-gap__large {
  margin-top: 30px;
}

.estimate-manage__company-link {
  margin-top: 24px;
  color: var(--gray2-color);
  cursor: pointer;
}

.estimate-manage__logo {
  width: 300px;
  height: 108px;
  border: 1px dashed var(--gray2-color);
  border-radius: 5px;
  text-align: center;
  padding: 24px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.estimate-manage__logo-text {
  color: #8b8b8b;
  margin-top: 30px;
}

.estimate-manage__logo-buttons {
  margin-top: 15px;
  color: var(--gray2-color);
  display: flex;
  justify-content: end;
  font-size: 14px;
  font-family: var(--inter-font);
}

.estimate-manage__logo-buttons > div {
  cursor: pointer;
}

.estimate-manage__logo-buttons-gap {
  margin-right: 25px;
}

.estimate-manage__logo-label {
  cursor: pointer;
}

.estimate-manage__divider {
  margin: 37px 0 33px;
}

.estimate-manage__padding {
  padding: 0 40px;
}

.estimate-manage__estimate-title {
  color: var(--gray2-color);
  font-size: 14px;
  font-family: var(--inter-font);
  margin-bottom: 14px;
}

.estimate-manage__create-customer {
  width: 370px;
  margin-top: 20px;
}

.estimate-manage__data-item {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: var(--font-family);
  color: var(--gray2-color);
  justify-content: end;
}

.estimate-manage__data-input {
  width: 165px;
  margin-left: 15px;
}

.estimate-manage__table-buttons {
  display: flex;
  margin-top: 15px;
  padding-left: 40px;
}

.estimate-manage__buttons-mobile {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
}

.estimate-manage__add-line {
  padding: 7px 17px;
  width: 101px;
  margin-right: 20px;
}

.estimate-manage__cancel-button {
  color: var(--error-color);
  font-family: var(--inter-font);
  font-size: 14px;
  cursor: pointer;
}

.estimate-manage__cancel-button:hover {
  transform: scale(1.03);
  transition: 0.3s all;
}

.estimate-manage__root-amount {
  display: flex;
  padding: 0 40px;
  box-sizing: border-box;
  justify-content: flex-end;
}

.estimate-manage__buttons_right {
  display: flex;
  padding: 0 40px;
  box-sizing: border-box;
  justify-content: flex-end;
  margin-top: 55px;
}

.estimate-manage__amount-block {
  color: var(--black-color);
  font-size: 14px;
  font-family: var(--font-family);
}

.estimate-manage__amount-line {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 15px;
}

.estimate-manage_amount-value {
  margin-left: 67px;
  width: 50px;
}

.estimate-manage__notes-title {
  color: var(--gray2-color);
  font-family: var(--inter-font);
  font-size: 14px;
}

.estimate-manage__textarea {
  width: 535px;
  margin-top: 14px;
}

.estimate-manage__bold-text {
  font-weight: 600;
  text-align: end;
}

.estimate-manage__tax-block {
  display: flex;
  align-items: center;
  justify-content: end;
}

.estimate-manage__tax-title {
  margin-right: 15px;
}

.estimate-table__tax-selecror {
  width: 65px;
}

.estimate-manage__textarea-footer {
  width: 100%;
  box-sizing: border-box;
  margin-top: 14px;
}

.estimate-manage__notes-title {
  margin-bottom: 14px;
  color: var(--gray2-color);
  font-size: 14px;
  font-family: var(--inter-font);
}

.estimate-manage__footer-textarea {
  width: 100%;
}

.estimate-manage__amount-item {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 22px;
  font-size: 14px;
  color: var(--black-color);
  font-family: var(--inter-font);
}

.estimate-manage__amount-item-mobile {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 22px;
  font-size: 14px;
  color: var(--black-color);
  font-family: var(--inter-font);
}

.estimate-manage__amount-value {
  color: var(--secondary-grey);
  text-align: start;
  min-width: 85px;
  margin-left: 50px;
}

.estimate-manage__bold-text {
  font-weight: 600;
}

.estimate-manage__tax-block {
  display: flex;
  align-items: center;
}

.estimate-manage__select {
  width: 66px;
  margin-left: 15px;
}

.estimate-manage__general-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 25px;
}

.estimate-manage__total-sum-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.estimate-manage__center {
  display: flex;
  justify-content: center;
  /* centers horizontally */
  align-items: center;
  padding-bottom: 25px;
  /* centers vertically */
  /* sets the height of the parent container to the full viewport height */
}

.estimate-manage__menu-buttons {
  display: flex;
  justify-content: space-between;
  /* centers horizontally */
  align-items: center;
  /* centers vertically */
  margin-top: 25px;
}

.estimate-manage__buttons-space {
  display: flex;
  justify-content: space-between;
  /* centers horizontally */
  align-items: center;
  /* centers vertically */
}

/* @media screen and (max-width: 2600px) {
	.estimate-manage__create {
		width: 1400px;
	}
}

@media screen and (max-width: 2000px) {
	.estimate-manage__create {
		width: 1200px;
	}
}

@media screen and (max-width: 1800px) {
	.estimate-manage__create {
		width: 1015px;
	}
}

@media screen and (max-width: 1600px) {
	.estimate-manage__preview {
		width: 285px;
	}
	.estimate-manage__create {
		width: 895px;
	}
}

@media screen and (max-width: 1400px) {
	.estimate-manage__preview {
		width: 240px;
	}
	.estimate-manage__create {
		width: 770px;
	}
} */
