.sidebar__wrapper {
  box-sizing: border-box;
  /* min-width: 250px; */
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: var(--gray3-color);
  font-family: var(--inter-font);
  font-size: 14px;
  justify-content: space-between;
  position: relative;
}

.sidebar__logo {
  padding: 33px 15px;
  height: 30px;
}

.sidebar__logo-wrapper {
  width: 230px;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.sideber-item__wrapper {
  padding: 15px 10px 15px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-grey);
  box-sizing: content-box;
  cursor: pointer;
}

.sidebar-item__title {
  margin-left: 12px;
  padding: 0 4rem 0 0;
  display: none;
}

.sidebar-subitem:hover,
.sidebar-item__info:hover {
  color: var(--primary1-color);
}

.sidebar-subitem {
  display: none;
}

.sidebar__open .sidebar-item__title {
  display: block;
}

.sidebar__open .sidebar-subitem {
  display: block;
}

.sidebar__open .sideber-item__wrapper {
  justify-content: space-between;
  padding: 15px 10px 15px 17px;
}

.sideber-item__wrapper > .MuiSvgIcon-root {
  display: none;
}

.sidebar__open .sideber-item__wrapper > .MuiSvgIcon-root {
  display: inline-block;
}

.sidebar-item__info {
  display: flex;
  align-items: center;
}

.sidebar-subitems__wrapper {
  margin: 5px 0 5px 40px;
  border-left: 1px solid var(--primary1-color);
}

.sidebar-subitem {
  margin-bottom: 22px;
  margin-left: 15px;
  cursor: pointer;
}

.sidebar-subitem:nth-last-child(1) {
  margin-bottom: 0;
}

.subitem__active {
  color: var(--primary1-color);
}

.sidebar-item__active {
  color: var(--primary1-color);
  border-right: 5px solid var(--primary1-color);
  background-color: var(--secondary1-color);
  padding-left: 17px;
}

.svg-icon {
  /* filter: invert(94%) sepia(0%) saturate(2%) hue-rotate(357deg) brightness(92%)
		contrast(96%); */
  color: #fff;
}

.sidebar__items-wrapper {
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.sidebar__items-wrapper::-webkit-scrollbar {
  width: 0 !important;
}
