.personal-information-field__label {
  color: var(--gray1-color);
  font-size: 14px;
  font-family: var(--inter-font);
  margin-bottom: 20px;
}

.personal-information-field__gap {
  margin-top: 35px;
}

.personal-information-field__divider {
  margin: 50px 0 70px;
}

.personal-information__change-button {
  margin: 60px 0;
}

.whiteBackgroundTextField {
  background: white;
}
