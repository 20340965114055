.custom-button {
  margin-bottom: 20px;
  margin-right: 10px;
  width: 112px;
  height: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #E5EBFF;
  border: none;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #0039FF;
}

@media (max-width: 768px) {
  .custom-button {
    width: 82px;
    height: 82px;
  }
}

.custom-button:hover {
  background-color: #E5EBFF;
}

.custom-button.selected {
  outline: 2px solid #0039FF;
}

.custom-span {
    color: #0039FF;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin: 10px 0;
    margin-top: 10px
}
