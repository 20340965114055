.dahboard-tooltip__wrapper {
  position: relative;
  width: 490px;
  height: 44px;
  right: 50px;
}

@media screen and (max-width: 1170px) {
  .dahboard-tooltip__wrapper {
    right: 145px;
  }
  }

  @media screen and (min-width: 1500px) {
    .dahboard-tooltip__wrapper {
      left: 100px;
    }
    }

.dashboard-tooltip__text {
  width: 520px;
  height: 65px;
  border: 1px solid #9acce5;
  background-color: var(--secondary1-color);
  color: var(--primary1-color);
  padding: 10px 75px 15px 15px;
  font-family: var(--inter-font);
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 8px;
  border-radius: 5px;
  z-index: 2;
}

.dashboard-tooltip__cover {
  width: 520px;
  height: 75px;
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 3;
}

.dashboard-tooltip__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 4;
  top: 12px;
  right: 15px;
  cursor: pointer;
}

@keyframes slide {
  0% {
    width: 490px;
  }
  100% {
    width: 0;
  }
}
