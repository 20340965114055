.estimates-list-table {
	width: 100%;
	font-family: var(--inter-font);
	font-size: 14px;
	border-collapse: collapse;
}

.estimates-list-table__head {
	border-bottom: 1px solid #d9d9d9;
	position: sticky;
	background-color: #fff;
	top: 0;
	color: var(--gray2-color);
}

.estimates-list-table__body {
	border-bottom: 1px solid var(--fields-color);
	cursor: pointer;
}

.estimates-list-table__cell {
	padding: 20px 0;
}

.estimates-list-table__cell:nth-last-child(1) {
	padding-right: 10px;
}

.estimates-list-table__dots-icon {
	cursor: pointer;
}

.estimate-status__wrapper {
	width: 105px;
	border-radius: 5px;
	padding: 3px 10px;
	box-sizing: border-box;
}

.estimate-status__orange {
	border: 1px solid #ffa614;
	color: #ffa614;
}

.estimate-status__red {
	border: 1px solid #b52c2c;
	color: #b52c2c;
}

.estimate-status__blue {
	border: 1px solid #314ba6;
	color: #314ba6;
}

.estimates-list-table__time {
	color: var(--gray2-color);
	font-size: 12px;
	margin-top: 5px;
}

.estimates-list-table__action {
	width: 10px;
	text-align: center;
}
