.filled-green-button__wrapper {
  background: var(--primary1-color);
  color: #fff;
  font-family: var(--inter-font);
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  display: block;
}
.filled-green-button__wrapper-large {
  padding: 15px;
  font-weight: 700;
  font-family: var(--mulish-font);
}
.filled-green-button__wrapper:focus:focus-visible {
  outline: none;
}
.filled-green-button__wrapper:hover {
  box-shadow: 0px 5px 20px rgba(70, 101, 137, 0.35);
  transition: 0.3s all;
}
.filled-green-button__wrapper:disabled {
  background-color: #7cbd7c;
  cursor: default;
  box-shadow: none;
}

@media screen and (max-width: 1400px) {
  .filled-green-button__wrapper {
    font-size: 14px;
    font-weight: 600;
  }
}
