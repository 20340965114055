.error-toast {
  font-family: var(--font-family);
  background-color: red;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding-left: 10px;
}

html,
body {
  background-color: var(--background-color) !important;
}

#root {
  overflow: auto;
}

.MuiPagination-root {
  margin-top: 1rem;
}

.MuiTab-root {
  text-transform: none !important;
  font-family: var(--secondary-font-family) !important;
}

.MuiButtonBase-root {
  text-transform: none !important;
}

.MuiChip-root {
  border-radius: 4px !important;
  padding: 2px 12px 2px 12px !important;
  height: auto !important;
}

.MuiChip-label {
  padding: 0 !important;
  text-transform: lowercase !important;
  text-overflow: clip !important;
}

.MuiChip-label::first-letter {
  text-transform: capitalize !important;
}

.MuiChip-icon {
  margin-left: 0 !important;
  margin-right: 2px !important;
}

.MuiButton-outlined {
  border-width: 2px !important;
}

@media (min-width: 1200px) {
  .MuiContainer-root {
    max-width: 100% !important;
  }
}

@media (min-width: 1536px) {
  .MuiContainer-root {
    max-width: 1536px !important;
  }
}
