.estimate-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.estimates-table__head {
  background-color: var(--background-color);
}

.estimate-table__cell {
  padding: 15px 0;
  font-weight: 600;
  font-size: 14px;
  font-family: var(--inter-font);
  color: var(--secondary-grey);
}

.estimate-table__cell:first-child {
  padding-left: 40px;
}

.estimate-table__select {
  width: 280px;
}

.estimate-table__description-field {
  color: var(--gray2-color);
  width: 255px;
}

.estimate-table__quantity-field {
  width: 75px;
  color: var(--gray2-color);
}

.estimate-table__amount-field {
  width: 95px;
  color: var(--gray2-color);
}

.estimate-table__body-cell {
  padding: 15px 0;
  padding-right: 20px;
  color: var(--secondary-grey);
}

.estimate-table__body-cell:first-child {
  padding-left: 40px;
}

.estimate-table__body-cell:last-child {
  padding-right: 40px;
}

.estimate-table__gray-cell {
  color: var(--secondary-grey);
}

@media screen and (max-width: 1400px) {
  .estimate-table__select {
    width: 180px;
  }
  .estimate-table__amount-field {
    width: 70px;
  }
}
