.sign-in-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.sign-in-wrapper-mobile {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}


.sign-in-subtitle {
  margin: 30px 0 50px 0;
  width: 520px;
}

.sign-in-subtitle-confirm {
  margin: 30px 0 10px 0;
}

.sign-in-subtitle-second {
  margin-bottom: 55px;
  width: auto;
}

.sign-in-content {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.highlighted-text {
  color: var(--primary1-color);
  cursor: pointer;
}

.sign-in__input {
  width: 100%;
  margin-bottom: 25px;
}

.sign-in__input-bottom {
  width: 520px;
  margin-bottom: 55px;
}

.change-password__input-bottom {
  width: 100%;
  margin-bottom: 25px;
}

.sign-in__terms-and-policy {
  width: 520px;
  text-align: center;
  margin: 65px auto 0;
}

.sign-in__register-now {
  text-align: center;
  margin-top: 105px;
}

.sign-in-img__wrapper {
  width: 50%;
  /* background-color: var(--secondary2-color); */
  display: flex;
  align-items: center;
  position: relative; 
}

.sign-in-img__wrapper img {
  border-radius: 15px;
}

@media screen and (min-width: 1200px) {
  .sign-in-img__wrapper {
    width: 43%;
  }
  }
.sign-in-img__wrapper-mobile {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.sign-in-img {
  width: 100%;
  height: 80%;
}

.sign-in-up__button {
  width: 520px;
}

.sign-in__reset-password-text {
  color: var(--primary1-color);
  cursor: pointer;
}

.sign-in__reset-password-text:hover {
  /* text-decoration: underline; */
  color: var(--secondary3-color);
}

.sign-in__reset-field {
  width: 520px;
}

.sign-in-footer-container {
  position: absolute;
  bottom: 20px; 
  left: 0;
  right: 0;
  text-align: center;
}

.sign-in-footer-logo {
  width: 200px;
}


.sign-in-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; 
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 5px; 
  background-color: #ccc; 
  cursor: pointer;
}

.dot.active {
  background-color: #fff; 
}

