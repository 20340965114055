.label-text-field__label {
	font-family: var(--inter-font);
	font-size: 16px;
	margin-bottom: 20px;
	color: var(--gray1-color);
}

.label-text-field__description {
	margin-left: 10px;
	font-size: 14px;
	color: var(--gray2-color);
}
