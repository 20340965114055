.custom-choice {
  margin-bottom: 20px;
  width: 184px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #E5EBFF;
  border: none;
  align-items: flex-start;
  border-radius: 10px;
  cursor: pointer;
}

.custom-choice:hover {
  background-color: #E5EBFF;
}

.custom-choice.selected {
  outline: 2px solid #0039FF;
}

.span-choice {
    color: #4D5E80;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin: 10px 0;
    margin-left: 24px;
}

.custom-choice-container {
  width: 23%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {

  .custom-choice-container {
    flex-direction: row;
    width: 100%; 
    overflow: auto; 
    scrollbar-width: thin; 
    scrollbar-color: #4D5E80 #E5EBFF; 
  }
  .custom-choice {
    margin-left: 20px;
  }

  .custom-choice.selected {
    background-color: #d0d9e5;
    outline: none;
  }
}