.sign-up-wrapper {
	width: 100%;
	display: flex;
	height: 100vh;
}

.image-tab__active {
	width: 25px;
	height: 10px;
	border-radius: 5px;
	background-color: var(--cta-color);
	margin: 0 10px;
}

.image-tab__inactive {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #d9d9d9;
	margin: 0 10px;
}

.sign-up-content {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sign-up-content__wrapper {
	width: 520px;
}

.sign-up-subtitle {
	margin: 30px 0 55px 0;
}

.sign-up__input {
	margin-bottom: 25px;
}

.sign-up-img__wrapper {
	width: 50%;
	background-color: var(--secondary2-color);
	display: flex;
	position: relative;
	align-items: center;
}

.sign-up-img {
	width: 100%;
}

.image-pagination__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: 55px;
	left: 48%;
}

.sign-up__business-type-item {
	width: 520px;
	white-space: normal;
}

.sign-up__business-type-item-chosen {
	overflow: hidden;
	text-overflow: ellipsis;
}
