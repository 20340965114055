.sign-in-up-button__wrapper {
	width: 100%;
	background: #43a344;
	color: #fff;
	font-family: var(--mulish-font);
	font-size: 18px;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 15px;
	border-radius: 10px;
	margin-top: 50px;
	cursor: pointer;
	border: none;
	display: block;
}
.sign-in-up-button__wrapper:focur:focus-visible {
	outline: none;
}
.sign-in-up-button__wrapper:hover {
	box-shadow: 0px 5px 20px rgba(70, 101, 137, 0.35);
	transition: 0.3s all;
}
.sign-in-up-button__wrapper:disabled {
	background-color: #7cbd7c;
	cursor: default;
	box-shadow: none;
}

.background-animation {
	animation: 3s loading;
}

@keyframes loading {
	0% {
		background: #dbe2eb;
	}
	12% {
		background: linear-gradient(
			to right,
			#43a344 0%,
			#43a344 12%,
			#dbe2eb 12%,
			#dbe2eb 100%
		);
	}
	25% {
		background: linear-gradient(
			to right,
			#43a344 0%,
			#43a344 25%,
			#dbe2eb 25%,
			#dbe2eb 100%
		);
	}
	37% {
		background: linear-gradient(
			to right,
			#43a344 0%,
			#43a344 37%,
			#dbe2eb 37%,
			#dbe2eb 100%
		);
	}
	50% {
		background: linear-gradient(
			to right,
			#43a344 0%,
			#43a344 50%,
			#dbe2eb 50%,
			#dbe2eb 100%
		);
	}
	62% {
		background: linear-gradient(
			to right,
			#43a344 0%,
			#43a344 62%,
			#dbe2eb 62%,
			#dbe2eb 100%
		);
	}
	100% {
		background: linear-gradient(
			to right,
			#43a344 0%,
			#43a344 85%,
			#dbe2eb 85%,
			#dbe2eb 100%
		);
	}
}
