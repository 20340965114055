.profile-tab__wrapper {
	width: 287px;
	margin-right: 20px;
	border-radius: 5px;
	padding: 20px;
	box-sizing: border-box;
	font-family: var(--inter-font);
	color: var(--black-color);
	border: 1px solid #d9d9d9;
	cursor: pointer;
}

.profile-tab__wrapper:last-of-type {
	margin-right: 0;
}

.profile-tab__title {
	font-size: 18px;
	margin-bottom: 15px;
}

.profile-tab__description {
	font-size: 12px;
}

.profile-tab__active {
	color: #fff;
	background-color: var(--cta-color);
	border-color: var(--cta-color);
}
