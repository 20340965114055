.text-field__wrapper {
	width: 100%;
	padding: 16px 10px;
	border-radius: 5px;
	border: 1px solid #d9d9d9;
	font-size: 16px;
	font-family: var(--inter-font);
	box-sizing: border-box;
}

.text-field__wrapper-success {
	border: 1px solid rgba(64, 129, 51, 1);
}

.text-field__wrapper-error {
	border-color: var(--error-color) !important;
}

.text-field__wrapper-inactive {
	border: 1px solid #d9d9d9;
}

.text-field__wrapper:focus:focus-visible {
	/* border: 1px solid #d9d9d9; */
	outline-width: 0;
}

.text-field__wrapper::placeholder {
	color: #9ca3af;
}

.text-field__wrapper:disabled::placeholder {
	color: #d9d9d9;
}

.registration-text-field__icon {
	position: absolute;
	cursor: pointer;
	width: 18px;
	bottom: 2px;
}

.text-field__icon-start {
	left: 15px;
}

.text-field__icon-end {
	right: 15px;
}

.text-field__label {
	position: relative;
}

.text-input-message {
	position: absolute;
	bottom: -35px;
	font-size: 15px;
	font-family: var(--inter-font);
	left: 5px;
}
