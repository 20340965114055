.cash-inflow__chart-wrapper {
	/* width: 900px; */
	height: 300px;
	position: relative;
}

.cahs-inflow__no-data {
	position: absolute;
	top: 105px;
	left: 280px;
	width: 350px;
	z-index: 5;
	background-color: #6f768e;
	border-radius: 5px;
	font-size: 10px;
	color: #ffffff;
	font-family: var(--inter-font);
	text-align: center;
	padding: 15px 0;
	box-sizing: border-box;
}
