.statement__wrapper {
  width: 60%;
  border-radius: 5px;
  box-shadow: 0px 5px 20px rgba(70, 101, 137, 0.35);
  padding: 45px 0;
  box-sizing: border-box;
  margin: 8% auto;
}

.button-common-style {
  font-weight: 600;
  height: 65px;
  width: 174px;
}

.default-statement__wrapper {
  background-color: #f5f7fb;
}

.steps-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.title-container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.image-icon {
  width: '40px';
  height: '40px';
  margin-right: '20px';
}

.image-icon-mobile {
  width: '20px';
  height: '20px';
  margin-right: '20px';
}

.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #99a6bf;
}

.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #99a6bf;
}

.statement__padding {
  padding: 0 30px;
  box-sizing: border-box;
}

.default-statement__block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  color: var(--gray2-color);
}

.default-statement__h4 {
  font-family: var(--mulish-font);
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #4d5e80;
}

.default-statement__text {
  font-family: var(--inter-font);
  font-size: 14px;
  margin-bottom: 5px;
}

.default-statement__bold {
  font-weight: 600;
}

.default-statement__block-gap {
  margin-bottom: 28px;
}

.default-statement__divider {
  margin: 15px 0 38px 0;
  background-image: linear-gradient(to right, green 27%, #d9d9d9 27%, #d9d9d9 100%);
}

.default-statement__text-line {
  background-color: #fbfcff;
  text-align: center;
  font-family: var(--inter-font);
  color: var(--gray2-color);
  font-size: 14px;
  padding: 14px 0;
  box-sizing: border-box;
  margin-top: 55px;
}

.default-statement__no-data {
  background-color: #fbfcff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 310px;
}

.default-statement__no-data-text {
  background-color: #edf1ff;
  border-radius: 5px;
  font-size: 10px;
  color: var(--gray2-color);
  font-family: var(--inter-font);
  width: 350px;
  padding: 16px 0;
  box-sizing: border-box;
  text-align: center;
}

.default-statement__amount-block {
  background-color: #fbfcff;
  padding: 11px 30px;
  box-sizing: border-box;
  font-family: var(--mulish-font);
  font-weight: 700;
  font-size: 18px;
  color: var(--gray2-color);
  margin-top: 48px;
  display: flex;
  justify-content: flex-end;
}

.default-statement__amount-block > div {
  margin-left: 50px;
}

.default-statement__amount-text {
  font-family: var(--inter-font);
  font-size: 14px;
  color: var(--gray2-color);
  margin-bottom: 12px;
  display: flex;
}

.default-statement__amount-text > div {
  margin-left: 25px;
}

.default-statement__image-block {
  display: flex;
  align-items: center;
}

.default-statement__image {
  width: 250px;
  border-radius: 5px;
  background-color: #fbfcff;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.default-statement__icon {
  width: 40px;
  height: 40px;
  background-color: #edf1ff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 600px) {
  .statement__wrapper {
    min-width: 750px;
  }
}

@media screen and (max-width: 1000px) {
  .default-statement__wrapper {
    width: auto;
    margin-left: 0px;
  }
}

/* .default-statement__wrapper {
  background-color: #f5f7fb;
  margin-left: 230px;
  margin-top: 80px;
} */
