.dashboard__container {
	background-color: "white";
	border-radius: "10px";
	padding-bottom: "50px" ;
}

.dashboard__outstanding-bar {
	width: 200px;
	height: 100px;
	margin-left: 5px;
	transform: rotate(-180deg);
	position: relative;
	cursor: pointer;
}

.dashboard__outstanding-bar-tooltip {
	position: absolute;
	transform: rotate(-180deg);
	right: 17px;
	bottom: -20px;
	width: 140px;
	height: 57px;
	border-radius: 5px;
	box-shadow: 0px 5px 20px rgba(70, 101, 137, 0.35);
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 1600px) {
	.dashboard__outstanding-bar {
		width: 135px;
	}
}

@media screen and (max-width: 1400px) {
	.dashboard__outstanding-bar {
		width: 130px;
	}
}

@media screen and (max-width: 1170px) {
	.dashboard__outstanding-bar {
		width: 95px;
	}
}

/* Mobile styles*/
@media screen and (max-width: 767px) {

	.dashboard__container {
		padding-left: "10px";
		padding-right: "10px"
	}

  .dashboard__outstanding-bar {
    width: 100%;
    height: auto; 
    margin-left: 0; 
    transform: none;
  }

  .dashboard__outstanding-bar-tooltip {
    transform: none; 
    right: 0;
    bottom: -30px;
    width: 100%;
    height: auto;
  }
}
