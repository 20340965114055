.icon {
  background-color: #E5EBFF;
  color: #0039FF;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}