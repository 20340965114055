.dashboard-header__info {
  display: flex;
  align-items: center;
}

.dashboard-header__info-title {
  font-size: 16px;
  font-family: var(--inter-font);
  color: var(--black-color);
  margin-right: 20px;
}

.dashboard-header__info-value {
  color: var(--cta-color);
  font-size: 25px;
  font-weight: 700;
  font-family: var(--mulish-font);
}

.dashboard-info__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

.dashboard__block {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.dashboard__block-title {
  font-size: 24px;
  font-weight: 600;
  font-family: var(--mulish-font);
  color: var(--gray1-color);
}

.dashboard__block-title > span {
  font-size: 14px;
  font-family: var(--inter-font);
  color: var(--gray2-color);
  font-weight: 400;
}

.dashboard-outstanding-data {
  margin-top: 50px;
}

.dashboard__block-amount {
  font-family: var(--mulish-font);
  font-size: 38px;
  color: var(--black-color);
  margin-bottom: 45px;
}

.dashboard-outstanding-customer {
  display: flex;
  margin-top: 9px;
  align-items: center;
}

.dashboard-outstanding-customer:first-of-type {
  margin-top: 0;
}

.dashboard-outstanding-customer-color {
  height: 10px;
  width: 10px;
  margin-right: 20px;
}

.dashboard-outstanding-chart__wrapper {
  display: flex;
  align-items: flex-end;
  position: relative;
}

.dashboard__outstanding-bar:first-of-type {
  margin-left: 0;
}

.dashboard-table__tabs {
  display: flex;
  align-items: center;
}

.dashboard-table__tab {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dashboard-table__tabs-green {
  color: var(--primary1-color);
  border-bottom: 3.5px solid var(--primary1-color);
}

.dashboard-table__tabs-amount {
  font-family: var(--inter-font);
  font-size: 14px;
  color: #9ca3af;
  margin-left: 10px;
  margin-top: 3px;
}

.dashboard-table__tabs-divider {
  width: 1px;
  height: 20px;
  background-color: #b1b0b0;
  margin: 0 10px;
}

.dashboard-table__wrapper {
  margin-top: 45px;
}

.dashboard-outstanding-customer-disabled-color {
  color: var(--gray2-color);
}

.dashboard-outstanding-chart__disabled {
  width: 350px;
  padding: 40px 0;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  background-color: #03a9f4;
  opacity: 0.7;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  bottom: 62px;
}

.dashboard-disabled-invoices__wrapper {
  width: 100%;
  padding: 134px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f7fb;
  border-radius: 5px;
  margin-top: 17px;
}

.dashboard-disabled-invoices__content {
  width: 350px;
  padding: 40px 0;
  box-sizing: border-box;
  background-color: #03a9f4;
  opacity: 0.7;
  text-align: center;
  color: #fff;
  border-radius: 5px;
}

.dashoard-no-data__tables {
  height: 370px;
  margin-top: 15px;
}

.dashboard-inflow__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.dashboard-inflow__selector {
  width: 165px;
}

.dashboard-inflow__chart-block {
  display: flex;
  justify-content: space-between;
}

.dashboard-inflow__amount {
  font-family: var(--mulish-font);
  font-size: 38px;
  margin-bottom: 100px;
}

.dashboard-inflow__amount-block {
  width: 30%;
}

.dashboard-inflow__chart {
  width: 70%;
}

@media screen and (max-width: 1600px) {
  .dashboard-inflow__amount-block {
    width: 20%;
  }
  .dashboard-inflow__chart {
    width: 75%;
  }
}

@media screen and (max-width: 1400px) {
  .dashboard-inflow__amount-block {
    width: 15%;
  }
  .dashboard-inflow__chart {
    width: 80%;
  }

  @media (max-width: 768px) {
    .dashboard-inflow__header {
      flex-direction: column;
      align-items: stretch; 
    }
    .dashboard-inflow__chart-block {
      flex-direction: column;
    }
    .dashboard-inflow__amount {
      margin-bottom: 30px;
    }
    .dashboard-inflow__amount-block {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  

  .dashboard-inflow__amount {
    font-size: 28px;
  }
}

@media screen and (max-width: 600px) {
  .dashboard-inflow__chart {
    width: 100%;
  }
}
