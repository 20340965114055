.product-manage__wrapper {
	padding: 70px;
	box-sizing: border-box;
}

.product-manage__header {
	display: flex;
	justify-content: space-between;
}

.product-manage__header-title {
	font-family: var(--mulish-font);
	color: var(--gray1-color);
	font-weight: 600;
	font-size: 20px;
}

.product-manage__header-subtitle {
	font-family: var(--inter-font);
	font-size: 14.2px;
	color: var(--gray2-color);
	margin-top: 15px;
	line-height: 20px;
}

.product-manage__fields-wrapper {
	margin-top: 60px;
}

input.product-manage__field {
	margin-bottom: 35px;
	width: 440px;
}

input.product-manage__field-amount {
	padding-left: 25px;
}

.product-manage__buttons {
	margin-top: 45px;
	display: flex;
}

.product-manage__buttons > button {
	margin-right: 25px;
}

.product-manage__textarea {
	width: 440px;
	margin-bottom: 35px;
	color: var(--black-color);
	box-sizing: border-box;
}

.product-manage__textarea-title {
	color: var(--gray1-color);
	font-family: var(--inter-font);
	font-size: 16px;
	margin-bottom: 20px;
}

.card-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.price {
font-size: 32px;
font-weight: 700;
line-height: 48px;
letter-spacing: -0.02em;
text-align: left;
color: #0039FF;
margin-right: 2px
}

.period {
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #99A6BF
}

.detail-info {
font-size: 11px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: #4D5E80
}

.check-container {
	display: flex;
	align-items: flex-start;
	padding: 10px 20px;

}

.subtitle {
font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
}

.subtitle-info {
font-size: 12px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color: var(--secondary-grey);
margin-top: 24px; 
}
