.invoices-list-top-bar {
	margin-top: 95px;
}

.invoice__general-info {
	display: flex;
	justify-content: space-between;
	margin-top: 70px;
}

.general-data-block {
	display: flex;
	margin-bottom: 17px;
	align-items: center;
}

.general-data__first-line {
	font-size: 14px;
	font-family: var(--inter-font);
	color: var(--black-color);
	margin-right: 10px;
	margin-top: 7px;
}

.invoice__general-info-value {
	font-size: 14px;
	color: var(--gray2-color);
	font-family: var(--inter-font);
}

.invoice__general-info-green {
	color: #66b656;
}

.invoices__no-data {
	height: 370px;
	margin-top: 15px;
}

.invoiceCard.MuiPaper-root {
  box-shadow: none;
}
