.table-no-data__wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5f7fb;
	border-radius: 5px;
}

.table-no-data__content {
	width: 350px;
	height: 50px;
	box-sizing: border-box;
	background-color: #6f768e;
	opacity: 0.7;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	border-radius: 5px;
	font-size: 12px;
	font-family: var(--inter-font);
}
