.square-checkbox-unckecked {
	width: 18px;
	height: 18px;
	border-radius: 4px;
	background-color: #fff;
	border: 1px solid var(--cta-color);
	display: flex;
	justify-content: center;
	margin-right: 10px;
	align-items: center;
}

.square-checkbox-checked {
	width: 12px;
	height: 12px;
	background-color: var(--cta-color);
	border-radius: 3px;
}

.circle-checkbox {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid var(--cta-color);
	box-sizing: border-box;
}

.circle-checkbox-active {
	border-width: 5px;
}
