.customer-manage-dialog__wrapper {
  padding: 50px 74px 70px 70px;
  box-sizing: border-box;
}

@media screen and (max-width: 1000px) {
  .customer-manage-dialog__wrapper {
    padding: 20px;
  }
}

.customer-manage-dialog__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.customer-manage-dialog__tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-manage-dialog__tab {
  width: 105px;
  padding: 10px 0;
  box-sizing: border-box;
  text-align: center;
  color: var(--black-color);
  font-family: var(--inter-font);
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  margin-right: 20px;
  line-height: 20px;
}

.customer-manage-dialog__tab-done {
  border: 2px solid var(--cta-color);
  font-weight: 500;
  color: var(--cta-color);
}

.customer-manage-dialog__tab:last-of-type {
  margin-right: 0;
}

.customer-manage-dialog__tabs-stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 60px 0;
}

.customer-manage-dialog__stepper-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid var(--cta-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.customer-manage-dialog__stepper-active {
  background-color: var(--cta-color);
}

.customer-manage-dialog__stepper-line {
  height: 2px;
  width: 75px;
  background-color: var(--cta-color);
  margin: 0 7px;
}

@media screen and (max-width: 1000px) {
  .customer-manage-dialog__stepper-line {
    width: 50px;
  }
}

.customer-manage-dialog__buttons {
  display: flex;
  margin-top: 30px;
}

.customer-manage-dialog__buttons > button {
  margin-right: 25px;
}

.shipping-address__checkbox {
  margin: 10px 0 55px 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: var(--inter-font);
  color: var(--gray1-color);
  cursor: pointer;
}
