.estimates-list-top-bar {
	margin-top: 55px;
}

.estimates-table__wrapper {
	width: 100%;
	margin-top: 15px;
	overflow: auto;
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;
}

.estimates-table__wrapper::-webkit-scrollbar {
	display: none;
}

.estimates-table__pagination {
	margin: 55px 0;
}

.estimates__no-padding {
	padding-bottom: 0;
}

.estimate__no-data {
	height: 535px;
	margin-top: 20px;
}
