.home-menu__wrapper {
	width: 100%;
	/* background-color: #edf1ff; */
	display: flex;
	justify-content: end;
	align-items: center;
}

.home-content__wrapper {
	width: 100%;
	/* overflow: auto; */
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
}

.home-content__wrapper::-webkit-scrollbar {
	width: 0 !important;
}

.home__wrapper {
	width: 100%;
	height: 100vh;
	display: flex;
	overflow: hidden;
}

.home-menu__profile {
	display: flex;
	align-items: center;
	font-size: 16px;
	border-radius: 5px;
	margin: 13px 40px 13px 0;
	padding: 7px 15px;
}

.home-menu-top__icon {
	margin-right: 12px;
}

.home-menu__notifications {
	margin-right: 28px;
	cursor: pointer;
	margin-top: 5px;
}

.home-menu__notification-wrapper {
	margin: 30px 40px 0;
	padding: 25px;
	box-sizing: border-box;
	border: 1px solid #ebcc7c;
	background-color: #fef8ca;
	display: flex;
	border-radius: 5px;
	font-size: 14px;
	font-family: var(--inter-font);
	justify-content: space-between;
	align-items: center;
}

.home-menu__notification-wrapper > img {
	width: 12px;
	cursor: pointer;
}

.home-menu__notification-hidden {
	position: absolute;
	top: -100px;
	transition-property: position;
	transition-duration: 2s;
}

.notification-warning__text {
	display: flex;
	align-items: center;
}

.notification-warning__text > img {
	margin-right: 15px;
}

.notification-warning__link-text {
	color: var(--cta-color);
	text-decoration: none;
}

@media screen and (max-width: 1400px) {
	.home-menu__notification-wrapper {
		font-size: 12px;
		padding: 20px;
	}
}

.paper-container{
	display: flex;
	justify-content: center;
	align-items: center;
}

