.content-header__content {
	width: 100%;
	display: flex;
	justify-content: space-between;
	padding-bottom: 24px;
}

.content-header__title {
	font-weight: 700;
	font-family: var(--mulish-font);
	font-size: 25px;
	padding-left: 5px;
	color:var(--secondary-grey)
}
