.text-field__root {
	padding: 7px 10px;
	border-radius: 5px;
	border: 1px solid var(--fields-color);
	box-sizing: border-box;
	font-family: var(--inter-font);
	font-size: 14px;
	width: 340px;
}

.text-field__root:disabled {
	background-color: transparent;
	color: var(--gray2-color);
}

.text-field__root:focus:focus-visible {
	/* border: 1px solid #d9d9d9; */
	outline-width: 0;
}

.text-field__root::placeholder {
	color: #9ca3af;
}

.text-field__root:disabled::placeholder {
	color: #d9d9d9;
}

.text-field__label {
	position: relative;
}

.text-field__icon {
	position: absolute;
	cursor: pointer;
	width: 16px;
	bottom: 10px;
	top: 4px;
}

.text-field__root-start {
	padding-left: 40px;
}

.text-field__root-end {
	padding-right: 40px;
}

.text-field__icon-start {
	left: 12px;
}

.text-field__icon-end {
	top: 3px;
	right: 12px;
}

.text-field__without-icon {
	padding: 7px 10px;
}
